
import { defineComponent } from "vue";
import Dropdown3 from "@/view/content/dropdown/Dropdown3.vue";

export default defineComponent({
  name: "widget-9",
  components: {
    Dropdown3,
  },
});
