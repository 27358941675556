
import { defineComponent, computed, ref, onMounted } from "vue";

export default defineComponent({
  name: "Widget1",
  components: {},
  setup() {
    const show = ref({});
    const day = ref([
      {
        title: "Top Authors",
        desc: "HTML/CSS/JS, Python\n",
        progress: "46%",
        icon: "media/icons/duotune/communication/com012.svg",
        status: "success",
      },
      {
        title: "Popular Authors",
        desc: "HTML, VueJS, Laravel",
        progress: "87%",
        icon: "media/icons/duotune/general/gen025.svg",
        status: "warning",
      },
      {
        title: "New Users",
        desc: "HTML/CSS/JS, Python",
        progress: "53%",
        icon: "media/icons/duotune/arrows/arr075.svg",
        status: "success",
      },
      {
        title: "Weekly Bestsellers",
        desc: "HTML/CSS/JS, Python",
        progress: "92%",
        icon: "media/icons/duotune/abstract/abs027.svg",
        status: "danger",
      },
    ]);
    const week = ref([
      {
        title: "Popular Authors",
        desc: "HTML, VueJS, Laravel",
        progress: "87%",
        icon: "media/icons/duotune/general/gen025.svg",
        status: "warning",
      },
      {
        title: "Top Authors",
        desc: "HTML/CSS/JS, Python\n",
        progress: "46%",
        icon: "media/icons/duotune/communication/com012.svg",
        status: "success",
      },
      {
        title: "New Users",
        desc: "HTML/CSS/JS, Python",
        progress: "53%",
        icon: "media/icons/duotune/arrows/arr075.svg",
        status: "success",
      },
      {
        title: "Weekly Bestsellers",
        desc: "HTML/CSS/JS, Python",
        progress: "92%",
        icon: "media/icons/duotune/abstract/abs027.svg",
        status: "danger",
      },
    ]);
    const month = ref([
      {
        title: "Top Authors",
        desc: "HTML/CSS/JS, Python\n",
        progress: "46%",
        icon: "media/icons/duotune/communication/com012.svg",
        status: "success",
      },
      {
        title: "New Users",
        desc: "HTML/CSS/JS, Python",
        progress: "53%",
        icon: "media/icons/duotune/arrows/arr075.svg",
        status: "success",
      },
      {
        title: "Weekly Bestsellers",
        desc: "HTML/CSS/JS, Python",
        progress: "92%",
        icon: "media/icons/duotune/abstract/abs027.svg",
        status: "danger",
      },
      {
        title: "Popular Authors",
        desc: "HTML, VueJS, Laravel",
        progress: "87%",
        icon: "media/icons/duotune/general/gen025.svg",
        status: "warning",
      },
    ]);

    const dataToShow = computed(() => {
      if (show.value === day.value) return day.value;
      if (show.value === week.value) return week.value;
      if (show.value === month.value) return month.value;
      return day.value;
    });

    const setCurrentData = (data) => {
      show.value = data;
    };

    onMounted(() => {
      show.value = day.value;
    });

    return {
      show,
      day,
      week,
      month,
      setCurrentData,
      dataToShow,
    };
  },
});
