
import { defineComponent, onMounted } from "vue";
import GeneralWidget1 from "@/view/content/widgets/general/Widget1.vue";
import TableWidget1 from "@/view/content/widgets/tables/Widget1.vue";
import TableWidget2 from "@/view/content/widgets/tables/Widget2.vue";
import StatsWidget1 from "@/view/content/widgets/stats/Widget1.vue";
import StatsWidget2 from "@/view/content/widgets/stats/Widget2.vue";
import ListWidget9 from "@/view/content/widgets/list/Widget9.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "start",
  components: {
    GeneralWidget1,
    TableWidget1,
    TableWidget2,
    StatsWidget1,
    StatsWidget2,
    ListWidget9,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Dashboards", "Start");
    });
  },
});
